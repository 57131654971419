import Menu from "react-burger-menu/lib/menus/slide";
import React from "react";
import { Typography } from "@mui/material/";
import LogoSvg from "/src/images/logo_h_color_white.svg";
import { Link } from "gatsby";

const CustomSurgicalLogo = (
  <Link to="/">
    <Typography
      variant="h6"
      component="h1"
      style={{ display: "flex", alignItems: "center" }}
    >
      <img
        src={LogoSvg}
        style={{ width: "150px", height: "auto", marginLeft: "1em" }}
      ></img>
    </Typography>
  </Link>
);

export default function Humburger() {
  return (
    <div
      style={{
        justifyContent: "space-between",
        display: "flex",
        backgroundColor: "#202026",
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: "1000",
      }}
    >
      {CustomSurgicalLogo}

      <div>
        <Menu right>
          <li>
            <Link to="/" activeClassName="nav_link_active" className="nav_link">
              Home
            </Link>
          </li>

          <li>
            <Link
              to="/about"
              activeClassName="nav_link_active"
              className="nav_link"
            >
              About
            </Link>
          </li>
          <div class="subnav">
            <div>Products </div>
            <div class="subnav-content_mob">
              <div className="subnav_hover_mob">
                <div style={{ display: "flex", marginBottom: "1em" }}>
                  <a className="navbar_h1_mob" style={{ fontWeight: "500" }}>
                    Hardware &#62;
                  </a>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <a className="navbar_h1_mob" style={{ fontWeight: "500" }}>
                      <Link
                        to="/microrec"
                        activeClassName="nav_link_active"
                        className="nav_link"
                      >
                        MicroREC
                      </Link>
                    </a>
                    <a className="navbar_h1_mob" style={{ fontWeight: "500" }}>
                      <Link
                        to="/optirec"
                        activeClassName="nav_link_active"
                        className="nav_link"
                      >
                        OptiREC
                      </Link>
                    </a>
                  </div>
                </div>
              </div>

              <div className="subnav_hover_mob">
                <div style={{ display: "flex", marginBottom: "1em" }}>
                  <a
                    className="navbar_h1_mob"
                    style={{ fontWeight: "500", width: "50%" }}
                  >
                    Software &#62;
                  </a>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <a className="navbar_h1_mob" style={{ fontWeight: "500" }}>
                      <Link
                        to="/connect"
                        activeClassName="nav_link_active"
                        className="nav_link"
                      >
                        MicroREC Connect
                      </Link>
                    </a>{" "}
                    <a className="navbar_h1_mob" style={{ fontWeight: "500" }}>
                      <Link
                        to="/microrec-app"
                        activeClassName="nav_link_active"
                        className="nav_link"
                      >
                        MicroREC App
                      </Link>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <li>
            <Link
              to="/blog"
              activeClassName="nav_link_active"
              className="nav_link"
            >
              Blog
            </Link>
          </li>

          <a
            href="https://connect.customsurgical.co/"
            style={{
              color: "white",
              display: "flex",
              alignItems: "center",
              marginRight: "2em",
              textAlign: "center",
              padding: "8px 15px 8px 10px",
              borderRadius: "15px",
              backgroundColor: "#8F4FFF",
              fontWeight: "600",
              width: "fit-content",
              marginBottom: "1em",
            }}
            target="_blank"
          >
            <div>Go to Connect</div>
          </a>
          <button className="shop">
            <a href="/store" style={{ color: "white" }}>
              Shop now
            </a>
          </button>
        </Menu>
      </div>
    </div>
  );
}

import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material/";

import { Link } from "gatsby";
import LogoSvg from "/src/images/logo_h_color_white.svg";
import microrec from "/src/images/header/new microrec-grey.png";
import banner from "/src/images/header/banner.png";
import micapp from "/src/images/header/MicroREC App Logo.png";
import smartphone from "/src/images/header/microrec-app.png";
import optirecLogo from "./opti-rec-1.svg";
import optirec from "./design-sem-nome-1.png";
import connect from "../connect/MicroREC Connect Logo (1).png";
import logo_connect from "../connect/Icon MicroREC Connect.png";
export default function Header() {
  const displayDesktop = () => {
    return (
      <Toolbar sx={{ justifyContent: "space-between", padding: "0" }}>
        {CustomSurgicalLogo}

        <ul className="nav_menu">
          <li className="padding_nav">
            <a>
              <Link
                to="/"
                activeClassName="nav_link_active"
                className="nav_link"
              >
                Home
              </Link>
            </a>
          </li>
          <li className="padding_nav">
            <Link
              to="/about"
              activeClassName="nav_link_active"
              className="nav_link"
              partiallyActive={true} // Add this prop
            >
              About
            </Link>
          </li>
          <div class="subnav">
            <button class="subnavbtn">
              Products <i class="fa fa-caret-down"></i>
            </button>
            <div class="subnav-content">
              <div className="subnav_block">
                <div className="subnav_block_hover">
                  <div className="navbar_h1">Hardware</div>
                  <div>
                    <div className="subnav_hover">
                      <a href="/microrec">
                        <div className="navbar_block">
                          <div style={{ width: "30%" }}>
                            <img
                              src={microrec}
                              style={{ width: "50%", marginRight: "2%" }}
                            ></img>
                          </div>
                          <div style={{ width: "80%", marginLeft: "-10%" }}>
                            <div style={{ fontWeight: "700" }}>
                              <img src={banner} style={{ width: "50%" }}></img>
                            </div>
                            <div style={{ fontSize: "13px" }}>
                              The ultimate optical system to digitize your
                              microscope or slit lamp
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="subnav_hover">
                      <a href="/optirec">
                        <div className="navbar_block">
                          <div style={{ width: "30%" }}>
                            <img
                              src={optirec}
                              style={{ width: "50%", marginRight: "2%" }}
                            ></img>
                          </div>
                          <div style={{ width: "80%", marginLeft: "-10%" }}>
                            <div style={{ fontWeight: "700" }}>
                              <img
                                src={optirecLogo}
                                style={{ width: "50%" }}
                              ></img>
                            </div>
                            <div style={{ fontSize: "13px" }}>
                              The slit lamp adapter for your diagnostic pictures
                            </div>
                          </div>
                        </div>{" "}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="subnav_block_hover">
                  <div className="navbar_h1">Software</div>
                  <a href="/connect">
                    <div className="navbar_block">
                      <div style={{ width: "30%" }}>
                        <img
                          src={logo_connect}
                          style={{ width: "50%", marginRight: "2%" }}
                        ></img>
                      </div>
                      <div style={{ width: "80%", marginLeft: "-10%" }}>
                        <div style={{ fontWeight: "700" }}>
                          <img src={connect} style={{ width: "50%" }}></img>
                        </div>
                        <div style={{ fontSize: "13px" }}>
                          Manage your medical data anywhere and at any time.
                        </div>
                      </div>
                    </div>
                  </a>{" "}
                  <a href="/microrec-app">
                    <div className="navbar_block">
                      <div style={{ width: "30%" }}>
                        <img
                          src={smartphone}
                          style={{ width: "50%", marginRight: "2%" }}
                        ></img>
                      </div>
                      <div style={{ width: "80%", marginLeft: "-10%" }}>
                        <div style={{ fontWeight: "700" }}>
                          <img src={micapp} style={{ width: "70%" }}></img>
                        </div>
                        <div style={{ fontSize: "13px" }}>
                          Free App to improve your recordings and organize them
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div
                style={{
                  marginLeft: "20px",
                  paddingBottom: "20px",
                  paddingTop: "10px",
                  display: "flex",
                }}
              >
                <div>
                  <a href="/contact-us">Contact</a>
                </div>
                &nbsp; | &nbsp;
                <div>
                  <a href="https://wa.me/491782064226?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about">
                    Sales Support
                  </a>
                </div>
              </div>
            </div>
          </div>

          <li className="padding_nav">
            <Link
              to="/blog"
              activeClassName="nav_link_active"
              className="nav_link"
              partiallyActive={true} // Add this prop
            >
              Blog
            </Link>
          </li>
        </ul>
        <a
          href="https://connect.customsurgical.co/"
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
            marginRight: "1em",
            textAlign: "center",
            padding: "8px 15px 8px 10px",
            borderRadius: "15px",
            backgroundColor: "#8F4FFF",
            fontWeight: "600",
          }}
          target="_blank"
        >
          <div style={{ marginLeft: "0.3em" }}>Go to Connect</div>
        </a>

        <button className="shop">
          <a href="/store" style={{ color: "white" }}>
            Shop now
          </a>
        </button>
      </Toolbar>
    );
  };

  const CustomSurgicalLogo = (
    <Link to="/">
      <Typography
        variant="h6"
        component="h1"
        style={{ display: "flex", alignItems: "center" }}
      >
        <img src={LogoSvg} style={{ width: "120px", height: "auto" }}></img>
      </Typography>
    </Link>
  );

  return <AppBar sx={styles.headerAppBar}>{displayDesktop()}</AppBar>;
}

const styles = {
  headerAppBar: {
    background: "#202026",
    paddingRight: "0px",
    paddingLeft: "0px",
  },
};
